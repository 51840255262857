// stylelint-disable property-blacklist, scss/dollar-variable-default

// SCSS RFS mixin
//
// Automated responsive font sizes
//
// Licensed under MIT (https://github.com/twbs/rfs/blob/master/LICENSE)

// Configuration

// Base font size
$rfs-base-font-size: 1.25rem !default;
$rfs-font-size-unit: rem !default;

@if $rfs-font-size-unit != rem and $rfs-font-size-unit != px {
  @error "`#{$rfs-font-size-unit}` is not a valid unit for $rfs-font-size-unit. Use `px` or `rem`.";
}

// Breakpoint at where font-size starts decreasing if screen width is smaller
$rfs-breakpoint: 1200px !default;
$rfs-breakpoint-unit: px !default;

@if $rfs-breakpoint-unit != px and $rfs-breakpoint-unit != em and $rfs-breakpoint-unit != rem {
  @error "`#{$rfs-breakpoint-unit}` is not a valid unit for $rfs-breakpoint-unit. Use `px`, `em` or `rem`.";
}

// Resize font size based on screen height and width
$rfs-two-dimensional: false !default;

// Factor of decrease
$rfs-factor: 10 !default;

@if type-of($rfs-factor) != "number" or $rfs-factor <= 1 {
  @error "`#{$rfs-factor}` is not a valid  $rfs-factor, it must be greater than 1.";
}

// Generate enable or disable classes. Possibilities: false, "enable" or "disable"
$rfs-class: false !default;

// 1 rem = $rfs-rem-value px
$rfs-rem-value: 16 !default;

// Safari iframe resize bug: https://github.com/twbs/rfs/issues/14
$rfs-safari-iframe-resize-bug-fix: false !default;

// Disable RFS by setting $enable-responsive-font-sizes to false
$enable-responsive-font-sizes: true !default;

// Cache $rfs-base-font-size unit
$rfs-base-font-size-unit: unit($rfs-base-font-size);

// Remove px-unit from $rfs-base-font-size for calculations
@if $rfs-base-font-size-unit == "px" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1);
}
@else if $rfs-base-font-size-unit == "rem" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1 / $rfs-rem-value);
}

// Cache $rfs-breakpoint unit to prevent multiple calls
$rfs-breakpoint-unit-cache: unit($rfs-breakpoint);

// Remove unit from $rfs-breakpoint for calculations
@if $rfs-breakpoint-unit-cache == "px" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1);
}
@else if $rfs-breakpoint-unit-cache == "rem" or $rfs-breakpoint-unit-cache == "em" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1 / $rfs-rem-value);
}

// Internal mixin that adds disable classes to the selector if needed.
@mixin _rfs-disable-class {
  @if $rfs-class == "disable" {
	// Adding an extra class increases specificity, which prevents the media query to override the font size
	&,
	.disable-responsive-font-size &,
	&.disable-responsive-font-size {
	  @content;
	}
  }
  @else {
	@content;
  }
}

// Internal mixin that adds enable classes to the selector if needed.
@mixin _rfs-enable-class {
  @if $rfs-class == "enable" {
	.enable-responsive-font-size &,
	&.enable-responsive-font-size {
	  @content;
	}
  }
  @else {
	@content;
  }
}

// Internal mixin used to determine which media query needs to be used
@mixin _rfs-media-query($mq-value) {
  @if $rfs-two-dimensional {
	@media (max-width: #{$mq-value}), (max-height: #{$mq-value}) {
	  @content;
	}
  }
  @else {
	@media (max-width: #{$mq-value}) {
	  @content;
	}
  }
}

// Responsive font size mixin
@mixin rfs($fs, $important: false) {
  // Cache $fs unit
  $fs-unit: if(type-of($fs) == "number", unit($fs), false);

  // Add !important suffix if needed
  $rfs-suffix: if($important, " !important", "");

  // If $fs isn't a number (like inherit) or $fs has a unit (not px or rem, like 1.5em) or $ is 0, just print the value
  @if not $fs-unit or $fs-unit != "" and $fs-unit != "px" and $fs-unit != "rem" or $fs == 0 {
	font-size: #{$fs}#{$rfs-suffix};
  }
  @else {
	// Remove unit from $fs for calculations
	@if $fs-unit == "px" {
	  $fs: $fs / ($fs * 0 + 1);
	}
	@else if $fs-unit == "rem" {
	  $fs: $fs / ($fs * 0 + 1 / $rfs-rem-value);
	}

	// Set default font size
	$rfs-static: if($rfs-font-size-unit == rem, #{$fs / $rfs-rem-value}rem, #{$fs}px);

	// Only add the media query if the font size is bigger than the minimum font size
	@if $fs <= $rfs-base-font-size or not $enable-responsive-font-sizes {
	  font-size: #{$rfs-static}#{$rfs-suffix};
	}
	@else {
	  // Calculate the minimum font size for $fs
	  $fs-min: $rfs-base-font-size + ($fs - $rfs-base-font-size) / $rfs-factor;

	  // Calculate difference between $fs and the minimum font size
	  $fs-diff: $fs - $fs-min;

	  // Base font-size formatting
	  $min-width: if($rfs-font-size-unit == rem, #{$fs-min / $rfs-rem-value}rem, #{$fs-min}px);

	  // Use `vmin` if two-dimensional is enabled
	  $variable-unit: if($rfs-two-dimensional, vmin, vw);

	  // Calculate the variable width between 0 and $rfs-breakpoint
	  $variable-width: #{$fs-diff * 100 / $rfs-breakpoint}#{$variable-unit};

	  // Set the calculated font-size
	  $rfs-fluid: calc(#{$min-width} + #{$variable-width}) #{$rfs-suffix};

	  // Breakpoint formatting
	  $mq-value: if($rfs-breakpoint-unit == px, #{$rfs-breakpoint}px, #{$rfs-breakpoint / $rfs-rem-value}#{$rfs-breakpoint-unit});

	  @include _rfs-disable-class {
		font-size: #{$rfs-static}#{$rfs-suffix};
	  }

	  @include _rfs-media-query($mq-value) {
		@include _rfs-enable-class {
		  font-size: $rfs-fluid;
		}

		// Include safari iframe resize fix if needed
		min-width: if($rfs-safari-iframe-resize-bug-fix, (0 * 1vw), null);
	  }
	}
  }
}

// The font-size & responsive-font-size mixins use RFS to rescale the font size
@mixin font-size($fs, $important: false) {
  @include rfs($fs, $important);
}

@mixin responsive-font-size($fs, $important: false) {
  @include rfs($fs, $important);
}