// ------------------------------------------------------------------
// FOOTER
// ------------------------------------------------------------------

.r-site-footer {
	margin-top: #{$spacing-unit/2};
	padding-bottom: #{$grid-gutter-width/2};
	@include breakpoint-min(md) {
		margin-top: $spacing-unit;
	}
}