// ------------------------------------------------------------------
// BUTTONS
// ------------------------------------------------------------------

.c-button {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: .875rem 2rem;
	font-size: 1rem;
	font-weight: $font-weight-bold;
	letter-spacing: 1px;
	text-decoration: none;
	text-align: center;
	line-height: 1.375;
	color: $color-white;
	background-color: $color-orange;
	@include transition(0.125s);
	span {
		display: block;
		&:first-child {

		}
		&:last-child {
			font-size: .75rem;
			color: $color-grey-dark;
		}
	}
	&:hover, &:focus {
		color: $color-white;
		background-color: darken($color-orange, 2%);
		transform: translateY(-2px);
	}
}