// ------------------------------------------------------------------
// HEADINGS
// ------------------------------------------------------------------

@mixin heading-base() {
	font-family: $type-raleway;
	font-weight: $font-weight-bold;
	line-height: $heading-line-height;
	letter-spacing: 1px;
	margin: 0 0 1.5rem;
}
@mixin h1 () {
	@include font-size($font-size-h1);
}
@mixin h2 {
	@include font-size($font-size-h2);
}
@mixin h3 {
	@include font-size($font-size-h3);
}
@mixin h4 {
	@include font-size($font-size-h4);
}
@mixin h5 {
	@include font-size($font-size-h5);
}
@mixin h6 {
	@include font-size($font-size-h6);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@include heading-base;
}

h1, .h1 {
	@include h1;
}
h2, .h2 {
	@include h2;
}
h3, .h3 {
	@include h3;
}
h4, .h4 {
	@include h4;
}
h5, .h5 {
	@include h5;
}
h6, .h6 {
	@include h6;
}

// ------------------------------------------------------------------
// PARAGRAPHS
// ------------------------------------------------------------------

p {
	@include font-size($font-size-p);
	line-height: $font-line-height;
	margin-bottom: 1.5rem;
}
strong {
	font-weight: $font-weight-bold;
}
em {
	font-style: italic;
}
small {
	font-size: rem($font-size-base) / 0.75;
}
blockquote {
	p {
		font-size: 1rem;
		font-weight: $font-weight-regular;
	}
}

// ------------------------------------------------------------------
// LINKS
// ------------------------------------------------------------------

a {
	color: $color-link;
	@include transition(0.25s);
	&:hover, &:focus {
		color: darken($color-link, 15%);
	}
	&:focus {
		outline: none;
	}
}
