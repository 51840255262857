// ------------------------------------------------------------------
// MEDIA QUERIES
// ------------------------------------------------------------------

@mixin mediamax($maxWidth) {
    @media only screen and (max-width: $maxWidth) {
        @content;
    }
}

@mixin mediamin($minWidth) {
    @media only screen and (min-width: $minWidth) {
        @content;
    }
}

@mixin breakpoint-max($class) {
	@if $class == xs {
		@media (max-width: $breakpoint-xs) { @content; }
	}
	@else if $class == sm {
		@media (max-width: $breakpoint-sm) { @content; }
	}
	@else if $class == md {
		@media (max-width: $breakpoint-md) { @content; }
	}
	@else if $class == lg {
		@media (max-width: $breakpoint-lg) { @content; }
	}
	@else if $class == xl {
		@media (max-width: $breakpoint-xl) { @content; }
	}
	@else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
	}
}

@mixin breakpoint-min($class) {
	@if $class == xs {
		@media (min-width: $breakpoint-xs) { @content; }
	}
	@else if $class == sm {
		@media (min-width: $breakpoint-sm) { @content; }
	}
	@else if $class == md {
		@media (min-width: $breakpoint-md) { @content; }
	}
	@else if $class == lg {
		@media (min-width: $breakpoint-lg) { @content; }
	}
	@else if $class == xl {
		@media (min-width: $breakpoint-xl) { @content; }
	}
	@else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
	}
}

// ------------------------------------------------------------------
// CLEARFIX
// ------------------------------------------------------------------

@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
}

// ------------------------------------------------------------------
// FONT STACK
// ------------------------------------------------------------------

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// ------------------------------------------------------------------
// PSEUDO ELEMENTS
// ------------------------------------------------------------------

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

// ------------------------------------------------------------------
// MAINTAIN RATIO
// ------------------------------------------------------------------

@mixin ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

// ------------------------------------------------------------------
// REM FONT SIZE
// ------------------------------------------------------------------

// @mixin font-size($size) {
// 	font-size: $size;
// 	font-size: rem($size);
// }

// ------------------------------------------------------------------
// TRANSITION
// ------------------------------------------------------------------

@mixin transition($property) {
	transition: all $property ease-in-out;
}

// ------------------------------------------------------------------
// SPACING UNITS
// ------------------------------------------------------------------

@function spacing-units($val) {
	@if type-of($val) == 'number' and unitless($val) == true {
		@return ($spacing-unit * $val);
	} @else {
		@error 'The supplied value (#{$val}) is not an integer.';
	}
}