// ------------------------------------------------------------------
// BASE
// ------------------------------------------------------------------

*, *:before, *:after {
	box-sizing: border-box;
}

html {
	font-family: $font-family;
	font-size: $font-size-base;
	font-weight: $font-weight-light;
	text-rendering: optimizeLegibility;
	word-spacing: 1px;
	color: $color-grey-medium;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	position: relative;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.r-page-main {
	flex-grow: 1;
	height: 100%;
}

img {
	max-width: 100%;
}

hr {
	display: block;
	border: 0;
	border-top: 2px solid $color-primary;
	max-width: 2rem;
	width: 100%;
	margin: 0 0 1.5rem;
}

// ------------------------------------------------------------------
// CONTAINER WIDTHS
// ------------------------------------------------------------------

.container--sm {
	@include breakpoint-min(sm) {
		max-width: $container-sm;
	}
}

.container--md {
	@include breakpoint-min(sm) {
		max-width: $container-sm;
	}
	@include breakpoint-min(md) {
		max-width: $container-md;
	}
}

.container--lg {
	@include breakpoint-min(sm) {
		max-width: $container-sm;
	}
	@include breakpoint-min(md) {
		max-width: $container-md;
	}
	@include breakpoint-min(lg) {
		max-width: $container-lg;
	}
}

.container--xl {
	@include breakpoint-min(sm) {
		max-width: $container-sm;
	}
	@include breakpoint-min(md) {
		max-width: $container-md;
	}
	@include breakpoint-min(lg) {
		max-width: $container-lg;
	}
	@include breakpoint-min(xl) {
		max-width: $container-xl;
	}
}

// ------------------------------------------------------------------
// FOCUS
// ------------------------------------------------------------------

*:focus {
	outline: 1px solid $color-primary;
}

// ------------------------------------------------------------------
// SELECTION
// ------------------------------------------------------------------

::selection {
	background: $color-primary;
	color: $color-black;
}

::-moz-selection {
	background: $color-primary;
	color: $color-black;
}

// ------------------------------------------------------------------
// PLACEHOLDERS
// ------------------------------------------------------------------

::-webkit-input-placeholder {
	 color: $color-grey-medium;
}

::-moz-placeholder {
	 color: $color-grey-medium;
}

:-ms-input-placeholder {
	 color: $color-grey-medium;
}
