// ------------------------------------------------------------------
// HEADER
// ------------------------------------------------------------------

.r-site-header {
	padding: $grid-gutter-width 0 0;

	@include breakpoint-min(lg) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
	}

	&__inner {
		display: flex;
		align-items: center;
	}

	/* + .r-page-main {
		margin-top: 5rem;
	} */
}

.c-site-logo {
	img {
		display: block;
	}
	&:first-child {
		max-width: 75px;
		@include breakpoint-min(lg) {
			max-width: 100%;
		}
	}
	&:last-child {
		max-width: 120px;
		@include breakpoint-min(lg) {
			max-width: 100%;
		}
		margin-left: auto;
	}
}

