// ------------------------------------------------------------------
// HAMBURGER
// ------------------------------------------------------------------

.c-hamburger {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	user-select: none;
	z-index: 9050;
	margin-left: auto;

	@include breakpoint-min(md) {
		display: none;
	}

	.line {
		fill:none;
		transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
		stroke: $color-black;
		stroke-width: 5;
		//stroke-linecap:round;
	}
	.top {
		stroke-dasharray: 40 121;
	}
	.bottom {
		stroke-dasharray: 40 121;
	}

	&.active {
		transform: rotate(45deg);
		.line {
			//stroke: $color-white;
		}
		.top {
			stroke-dashoffset: -68px;
		}
		.bottom {
			stroke-dashoffset: -68px;
		}
	}
}