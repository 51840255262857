// stylelint-disable declaration-no-important

//
// Text
//

// Alignment

.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.text#{$infix}-left   { text-align: left !important; }
		.text#{$infix}-right  { text-align: right !important; }
		.text#{$infix}-center { text-align: center !important; }
		.text#{$infix}-justify { text-align: justify !important; }
  	}
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
