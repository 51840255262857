// ------------------------------------------------------------------
// FORMS - BASE STYLING
// ------------------------------------------------------------------

%base-form-fields {
	display: block;
	width: 100%;
	border: 1px solid $color-white;
	background: $color-white;
	font-family: $font-family;
	@include font-size(16px);
	font-weight: $font-weight-regular;
	color: $color-black;
	z-index: 99;
	@include transition(0.2s);

	&:focus {
		outline: none;
		border-bottom: 1px solid $color-secondary;
	}
	&::placeholder {
		color: lighten( $color-primary, 25% );
	}
}

.c-form {
	label {
		display: block;
		margin-bottom: .75rem;
		@include font-size(14px);
		font-weight: $font-weight-bold;
		color: $color-black;
	}

	input[type="radio"] {
		margin-right: 10px;
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="url"],
	select {
		@extend %base-form-fields;
		height: 45px;
		padding: 0 10px;
	}

	textarea {
		@extend %base-form-fields;
		padding: 15px;
		resize: none;
		height: 150px;
	}

	input[type="submit"],
	input[type="button"] {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: 300px;
		padding: 18px 30px;
		text-align: center;
		text-decoration: none;
		@include font-size(16px);
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		line-height: 1;
		background: $color-secondary;
		color: $color-white;
		border: 0;
		cursor: pointer;
		@include transition(0.25s);

		&:hover {
			background: darken( $color-secondary, 10% );
			color: $color-white;
		}
	}
}

// ------------------------------------------------------------------
// FORMS - GRAVITY FORMS STYLING
// ------------------------------------------------------------------

.gf_page_steps {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin: 3rem 0;
	// margin-bottom: 3rem;

	&:before {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		transform: translateY(-50%);
		height: 2px;
		background: darken( $color-secondary, 50% );
		z-index: 1;
	}
}

.gf_step {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: $color-secondary;
	border: 2px solid darken( $color-secondary, 50% );
	z-index: 2;

	code,
	.gf_step_number {
		display: none;
	}
}

.gf_step_completed {
	background: darken( $color-secondary, 50% );
	border: 2px solid darken( $color-secondary, 50% );
}

.gf_step_clear {
	display: none;
}

.gfield + .gfield {
	margin-top: 1.5rem;
}

.hidden_label label {
	display: none;
}

.ginput_container_consent,
.ginput_container_checkbox,
.ginput_container_radio {
	label {
		display: inline-block;
		position: relative;
		padding-left: 30px;
		padding-top: 3px;
		line-height: 1.8;
		@include font-size(14px);
		font-weight: $font-weight-regular;
		color: $color-black;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			top: 6px;
			left: 0;
			width: 18px;
			height: 18px;
			line-height: 16px;
			text-align: center;
			border: 1px solid $color-black;
			background: $color-white;
			color: $color-white;
			@include transition(.25s);
		}
	}
	input {
		position: absolute;
		left: -99999px;
	}
	input[type="checkbox"]:checked + label:before,
	input[type="radio"]:checked + label:before {
		content: "\f00c";
		font-family: 'Font Awesome 5 Pro';
		font-size: 12px;
		font-weight: 400;
		color: $color-black;
	}
}

.gf_left_half,
.gf_right_half,
.gf_left_third,
.gf_middle_third,
.gf_right_third {
	@include breakpoint-min(md) {
		display: inline-block;
	}
}
.gf_left_half,
.gf_right_half {
	@include breakpoint-min(md) {
		width: 50%;
	}
}
.gf_left_third,
.gf_middle_third,
.gf_right_third {
	@include breakpoint-min(md) {
		width: 33.3333%;
	}
}
.gf_left_half,
.gf_left_third {
	@include breakpoint-min(md) {
		padding-right: 15px;
	}
}
.gf_middle_third {
	@include breakpoint-min(md) {
		padding: 0 15px;
	}
}
.gf_right_half,
.gf_right_third {
	@include breakpoint-min(md) {
		padding-left: 15px;
	}
}

.gform_footer,
.gform_page_footer {
	margin-top: 2rem;
}

.gform_button {
	background: $color-primary !important;
	margin-right: 10px !important;
}

.validation_error {
	display: block;
	padding: 10px 25px;
	background: $color-secondary;
	@include font-size(14px);
	color: $color-white;
	margin-bottom: 2rem;
}

.gfield_error {
	input[type="text"],
	input[type="email"],
	select {
		border: 1px solid $color-secondary;
	}
	.ginput_container_consent,
	.ginput_container_checkbox,
	.ginput_container_radio {
		label:before {
			border: 2px solid $color-secondary;
		}
	}
}

.validation_message {
	display: none;
	@include font-size(12px);
	margin-top: .5rem;
	color: #999;
	font-style: italic;
}

.gform_confirmation_message {
	@include font-size($font-size-p);
	color: lighten( $color-primary, 25% );
	line-height: $font-line-height;
}

.ui-datepicker {
    display: none;
    padding: .2em .2em 0;
    width: 17em;
}

.ui-datepicker .ui-datepicker-header {
    padding: .2em 0;
    position: relative;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    height: 1.8em;
    position: absolute;
    top: 2px;
    width: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover { top: 1px; }

.ui-datepicker .ui-datepicker-prev { left: 2px; }

.ui-datepicker .ui-datepicker-next { right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover { left: 1px; }

.ui-datepicker .ui-datepicker-next-hover { right: 1px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: block;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
}

.ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year { width: 100%; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year { width: 49%; }

.ui-datepicker table {
    border-collapse: collapse;
    font-size: .9em;
    margin: 0 0 .4em;
    width: 100%;
}

.ui-datepicker th {
    border: 0;
    font-weight: bold;
    padding: .7em .3em;
    text-align: center;
}

.ui-datepicker td {
    border: 0;
    padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: .7em 0 0 0;
    padding: 0 .2em;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    cursor: pointer;
    float: right;
    margin: .5em .2em .4em;
    overflow: visible;
    padding: .2em .6em .3em .6em;
    width: auto;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float: left; }

/* with multiple calendars */

.ui-datepicker.ui-datepicker-multi { width: auto; }

.ui-datepicker-multi .ui-datepicker-group { float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0 auto .4em;
    width: 95%;
}

.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }

.ui-datepicker-row-break {
    clear: both;
    font-size: 0em;
    width: 100%;
}

/* RTL support */

.ui-datepicker-rtl { direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
    left: auto;
    right: 2px;
}

.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
    left: auto;
    right: 1px;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane { clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button { float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current { float: right; }

.ui-datepicker-rtl .ui-datepicker-group { float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 1px;
    border-right-width: 0;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */

.ui-datepicker-cover {
    filter: mask(); /*must have*/
    height: 200px; /*must have*/
    left: -4px; /*must have*/
    position: absolute; /*must have*/
    top: -4px; /*must have*/
    width: 200px; /*must have*/
	z-index: -1; /*must have*/
}