// ------------------------------------------------------------------
// COLORS
// ------------------------------------------------------------------

// Mixin to generate the background color classes
@mixin background-color($class-name, $background-color) {
    .u-bg--#{$class-name} {
        background: $background-color;
    }
}

@include background-color('primary', $color-primary);
@include background-color('secondary', $color-secondary);