// ------------------------------------------------------------------
// DUO
// ------------------------------------------------------------------

.c-duo {
	overflow: hidden;
	&__row {
		margin: 0 #{-$grid-gutter-width};
		@include breakpoint-min(lg) {
			display: flex;
		}
	}
	&__col {
		position: relative;
		padding: $grid-gutter-width;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		@include breakpoint-min(lg) {
			min-height: 100vh;
			width: 50%;
		}
		&:first-child {
			background-color: $color-white;
		}
		&:last-child {
			background-color: $color-grey-light;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				z-index: -1;
				height: 100%;
				width: 9000px;
				background-color: $color-grey-light;
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}
			@include breakpoint-min(lg) {
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: auto;
					z-index: -1;
					height: 100%;
					width: 50vw;
					background-color: $color-grey-light;
				}
				&:after {
					content: none;
				}
			}
		}
	}
}